import React, { useContext, useEffect } from 'react'

import { Grid, Container } from 'semantic-ui-react'
import Footer from '../components/footer/Footer'
import AnimatedButton from '../components/AnimatedButton/AnimatedButton'
import { Link, useNavigate } from 'react-router-dom'
import UserContext from '../lib/UserContext'
import { useMediaQuery } from 'react-responsive'

const Landing = () => {
  const user = useContext(UserContext)
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    if (user?.authenticated) {
      navigate('/dashboard')
    }
  }, [user, navigate])

  return (
    <main>
      <Grid
        padded
        style={{
          height: '400px',
          background:
            'linear-gradient(110deg, rgba(52,73,94,1) 55%, rgba(29,187,155,0.9) 55%), url("/assets/bg.jpg") center',
          backgroundSize: 'cover',
        }}
      >
        <Grid.Row>
          <Grid.Column
            width={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ maxWidth: '500px', width: '80%' }}>
              <img
                style={{ height: 'auto', marginRight: '-20rem', width: '100%' }}
                src="/assets/CP_Ambassador.svg"
                alt="Logo"
              />
              <div className="join-login-buttons" style={{ display: 'flex', marginTop: '1.5rem' }}>
                <AnimatedButton
                  className="become-ambassador-button primary-button mr-4"
                  style={{ flex: 2, justifyContent: 'center' }}
                  intensity={-0.05}
                  onClick={() =>
                    window.open(
                      'https://survey.collegepulse.com/jfe/form/SV_eKB6S77crKujHeu?growthChannel=ambassadorWeb',
                      '_blank',
                    )
                  }
                >
                  <b>{isMobile ? 'Become ambassador' : 'Become an ambassador'}</b>
                </AnimatedButton>
                <Link to="/login" className="login-button">
                  <AnimatedButton
                    style={{ flex: 1, justifyContent: 'center' }}
                    intensity={-0.05}
                    className="primary-button mr-4"
                  >
                    <b>Log In</b>
                  </AnimatedButton>
                </Link>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={6}></Grid.Column>
        </Grid.Row>
      </Grid>
      <Container text style={{ paddingTop: '6rem' }}>
        <h1
          style={{
            fontSize: '3rem',
            textAlign: 'center',
            marginBottom: '3rem',
          }}
        >
          <span style={{ fontWeight: 200 }}>What is the </span>College Pulse Ambassador Program?
        </h1>
        <p>
          The College Pulse Ambassador Program is a network of college students across the country
          that assists us in collecting data, creating campus communities, and researching students.
          When a survey becomes available, CP Ambassadors at participating universities are notified
          and can begin sharing their survey link. Students who complete the survey are rewarded
          with a gift card. Then, CP Ambassadors are paid for each survey they collect. The student
          is rewarded, the Ambassador is rewarded, and we collect data! It's a win-win-win*
          situation. Sound like your cup of tea? Apply to be a College Pulse Ambassador today!
        </p>
        <img
          style={{ width: '100%', marginTop: '2rem' }}
          src="/assets/illustration.svg"
          alt="ilustration"
        />
      </Container>
      <Container
        text
        className="sign-up"
        style={{
          marginTop: '6rem',
          padding: '2rem',
          color: 'white',
          marginBottom: '5rem',
          width: '100%',
          position: 'relative',
        }}
      >
        <h1 style={{ fontSize: '3.25rem' }}>
          <span style={{ fontWeight: 300 }}>Become a</span>
          <br /> College Pulse Ambassador
        </h1>
        <p>To start sharing College Pulse, click below!</p>

        <AnimatedButton
          className="primary-button"
          style={{
            justifyContent: 'center',
            width: '100%',
            marginTop: '2rem',
            fontSize: '1.15rem',
          }}
          intensity={-0.05}
          onClick={() =>
            window.open(
              'https://survey.collegepulse.com/jfe/form/SV_eKB6S77crKujHeu?growthChannel=ambassadorWeb',
              '_blank',
            )
          }
        >
          <b>Become an ambassador</b>
        </AnimatedButton>
      </Container>
      <Footer />
    </main>
  )
}

export default Landing
